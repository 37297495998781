const items = [
  {
    title: 'Content',
    hashes: [
      'deleteText',
      'getContents',
      'getLength',
      'getText',
      'insertEmbed',
      'insertText',
      'setContents',
      'setText',
      'updateContents',
    ],
  },
  {
    title: 'Formatting',
    hashes: ['format', 'formatLine', 'formatText', 'getFormat', 'removeFormat'],
  },
  {
    title: 'Selection',
    hashes: ['getBounds', 'getSelection', 'setSelection'],
  },
  {
    title: 'Editor',
    hashes: ['blur', 'focus', 'disable', 'enable', 'hasFocus', 'update'],
  },
  {
    title: 'Events',
    hashes: [
      'text-change',
      'selection-change',
      'editor-change',
      'off',
      'on',
      'once',
    ],
  },
  {
    title: 'Model',
    hashes: [
      'find-experimental',
      'getIndex-experimental',
      'getLeaf-experimental',
      'getLine-experimental',
      'getLines-experimental',
    ],
  },
  {
    title: 'Extension',
    hashes: ['debug', 'import', 'register', 'addContainer', 'getModule'],
  },
];

export default items;
